@import "src/assets/styles/font-face.scss";

@include font-face(Avenir-LT-Std-35-Light,          "/fonts/Avenir/Avenir-LT-Std-35-Light", 100, normal, otf);
@include font-face(Avenir-LT-Std-45-Book,           "/fonts/Avenir/Avenir-LT-Std-45-Book", 200, normal, otf);
@include font-face(Avenir-LT-Std-45-Book-Oblique,   "/fonts/Avenir/Avenir-LT-Std-45-Book-Oblique", 200, normal, otf);
@include font-face(Avenir-LT-Std-55-Oblique,        "/fonts/Avenir/Avenir-LT-Std-55-Oblique", 300, normal, otf);
@include font-face(Avenir-LT-Std-55-Roman,          "/fonts/Avenir/Avenir-LT-Std-55-Roman", 300, normal, otf);
@include font-face(Avenir-LT-Std-65-Medium,         "/fonts/Avenir/Avenir-LT-Std-65-Medium", 400, normal, otf);
@include font-face(Avenir-LT-Std-65-Medium-Oblique, "/fonts/Avenir/Avenir-LT-Std-65-Medium-Oblique", 400, normal, otf);
@include font-face(Avenir-LT-Std-85-Heavy,          "/fonts/Avenir/Avenir-LT-Std-85-Heavy", 500, normal, otf);
@include font-face(Avenir-LT-Std-85-Heavy-Oblique,  "/fonts/Avenir/Avenir-LT-Std-85-Heavy-Oblique", 500, normal, otf);
@include font-face(Avenir-LT-Std-95-Black,          "/fonts/Avenir/Avenir-LT-Std-95-Black", 600, normal, otf);
@include font-face(Avenir-LT-Std-95-Black-Oblique,  "/fonts/Avenir/Avenir-LT-Std-95-Black-Oblique", 600, normal, otf);


@include font-face(D-DIN,               "/fonts/Din/D-DIN", 400, normal, otf);
@include font-face(D-DIN-Bold,          "/fonts/Din/D-DIN-Bold", 400, normal, otf);
@include font-face(D-DIN-Italic,        "/fonts/Din/D-DIN-Italic", 400, normal, otf);
@include font-face(D-DINCondensed,      "/fonts/Din/D-DINCondensed", 400, normal, otf);
@include font-face(D-DINCondensed-Bold, "/fonts/Din/D-DINCondensed-Bold", 400, normal, otf);
@include font-face(D-DINExp,            "/fonts/Din/D-DINExp", 400, normal, otf);
@include font-face(D-DINExp-Bold,       "/fonts/Din/D-DINExp-Bold", 400, normal, otf);
@include font-face(D-DINExp-Italic,     "/fonts/Din/D-DINExp-Italic", 400, normal, otf);
