@import "./global.scss";

strong {
  font-weight: bold;
}

html,
body,
#root,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;

  strong {
    font-weight: bold;
  }
}

* {
  font-family: D-DIN;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

// to fix "process is not defined" with a transparent overlay over the entire app
iframe {
    display: none !important;
}
