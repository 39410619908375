@import "../../../assets/styles/variables";

.InfoMessageWindowBg {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
}

.InfoMessageWindow {
    position: absolute;
    z-index: 999;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .InfoMessageBody {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: $lighten;
        height: 400px;
        width: 70%;
        box-shadow: 0px 8px 10px rgb(0 0 0 / 75%);
        padding: 30px;

        .InfoMessageClose {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }

        .InfoMessageTitle {
            font-size: 24px;
            margin: 0px;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-family: D-DINCondensed;
        }

        .InfoMessageImage {
            height: 100px;
            width: 100px;
        }

        .InfoMessageDescription {
            color: #111;
            font-size: 24px;
            margin: 0px;
            letter-spacing: 1px;
            text-align: center;
        }

        .InfoMessageButton {
            width: 100%;
        }
    }

    @media (min-width: 500px) {
        .InfoMessageBody {
            width: 100%;
            max-width: 300px;
            height: 100%;
            max-height: 400px;
        }
    }
}
