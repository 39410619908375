@import "src/assets/styles/variables.scss";
:root {
	--face: #e4ecf7;
	--lowlight: #111;
	--side: #e4ecf7;
	--coin-size: 4.5rem;
	--coin-face: url("../../../assets/images/loading_image.svg");
}

.Loding {
	height: 100%;
	align-items: center;
	box-sizing: border-box;
	min-height: 100vh;
	background-color: $secondary;
	display: flex;
	.LoadingCoin {
		width: 7rem;
		height: 7rem;
		left: 0;
		right: 0;
		margin: auto;
		transform-style: preserve-3d;
		position: relative;
		animation: fly 5.2s ease-in-out 0s infinite alternate;

		.LoadingBlock,
		.LoadingBlockBack {
			backface-visibility: hidden;
			position: absolute;
			top: 0;
			left: 0;
		}

		.LoadingBlock {
			transform: translateZ(-0.005em);
			animation: flip-head 1s linear 0s infinite alternate;
		}
		.LoadingBlockBack {
			animation: flip-tail 1s linear 0s infinite alternate;
			transform: translateZ(-0.755em);
		}

		.LoginShadow {
			position: relative;
			justify-content: center;
			display: flex;
			margin: 0 auto;
			top: 200px;
			background: rgba(0, 0, 0, 0.2);
			height: 20px;
			width: 60px;
			animation: coinshadow 3s infinite 2s;
			border-radius: 100%;
		}

		@keyframes coinshadow {
			20% {
				transform: scale(0.1);
			}

			50% {
				transform: scale(0);
			}
		}

		@keyframes fly {
			0% {
				top: -160%;
			}
			100% {
				top: 100%;
			}
		}

		@keyframes flip-tail {
			100% {
				transform: rotateX(360deg);
			}
			50% {
				transform: rotateX(0deg);
			}
		}

		.LoadingBlock {
			margin-top: 30px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			position: relative;
			width: 100%;
			max-width: 220px;
			border-radius: 4px;
			-webkit-perspective: 420px;
			perspective: 420px;
			-webkit-transform-style: preserve-3d;
			transform-style: preserve-3d;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform-style: preserve-3d;
			position: absolute;
			animation: fly 1.5s ease-in-out 0s infinite alternate;
			flex-direction: column;
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.LoadingImage {
				max-width: 450px;
				width: 7rem;
				height: 7rem;
				flex-direction: column;
				align-items: center;
				height: var(--coin-size);
				width: var(--coin-size);
				margin: 0.5rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				animation: spin 1s linear infinite;
				background-color: white;
				border-radius: 100%;
				&::after {
					content: "";
					display: block;
					position: relative;
					height: var(--coin-size);
					width: var(--coin-size);
					border-radius: 100%;
				}
				@keyframes spin {
					0% {
						width: var(--coin-size);
						box-shadow: 0 0 0 var(--side-dark);
						animation-timing-function: ease-in;
					}
					49.999% {
						width: 0.1rem;
						height: var(--coin-size);
						box-shadow: 0.05rem 0 0 var(--side), 0.1rem 0 0 var(--side), 0.15rem 0 0 var(--side),
							0.2rem 0 0 var(--side), 0.25rem 0 0 var(--side), 0.3rem 0 0 var(--side),
							0.35rem 0 0 var(--side), 0.4rem 0 0 var(--side), 0.45rem 0 0 var(--side),
							0.5rem 0 0 var(--side), 0.55rem 0 0 var(--side), 0.6rem 0 0 var(--side),
							0.65rem 0 0 var(--side), 0.7rem 0 0 var(--side), 0.75rem 0 0 var(--side);
						transform: translateX(-0.375rem);
						animation-timing-function: linear;
					}
					50.001% {
						width: 0.1rem;
						height: var(--coin-size);
						box-shadow: -0.05rem 0 0 var(--side), -0.1rem 0 0 var(--side), -0.15rem 0 0 var(--side),
							-0.2rem 0 0 var(--side), -0.25rem 0 0 var(--side), -0.3rem 0 0 var(--side),
							-0.35rem 0 0 var(--side), -0.4rem 0 0 var(--side), -0.45rem 0 0 var(--side),
							-0.5rem 0 0 var(--side), -0.55rem 0 0 var(--side), -0.6rem 0 0 var(--side),
							-0.65rem 0 0 var(--side), -0.7rem 0 0 var(--side), -0.75rem 0 0 var(--side);
						transform: translateX(0.375rem);
						animation-timing-function: ease-out;
					}
					100% {
						width: var(--coin-size);
						box-shadow: 0 0 0 var(--side-dark);
					}
				}
			}
		}
	}
}
