@import "src/assets/styles/variables.scss";
@import "src/assets/styles/font-face.scss";

.ButtonWrapper {
  .Button {
    font-size: 24px;
    padding: 15px 20px;
    text-align: center;
    cursor: pointer;
    border: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    font-family: D-DINCondensed;
    text-transform: uppercase;

    &.lowercase {
      text-transform: lowercase;
    }

    &.primary {
      background-color: $primary;
      color: white;
    }

    &.secondary {
      background-color: $secondary;
      color: white;
    }

    &.blank {
      background-color: transparent;
      box-shadow: none;
      color: white;
    }

    &.default {
      background-color: #f8f9fa;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      color: #d4d4d4;
    }

    &.square {
      height: 40px;
      width: 40px;
      padding: 0;
    }

    &.disabled {
      pointer-events: none;
      background-color: #f8f9fa;
      opacity: 0.8;
      color: #d5d5d5;
    }

    &.isLoading {
    }

    &.selected {
      background-color: $grey;
    }
  }
}
